import React from 'react';

import STRINGS from '../strings';
import LINKS from '../links'

import '../styles/style.css';


class App extends React.Component {

  render() {

    return (
      <div>
        <div className="top">
          <div className="str1">{STRINGS.str1}</div>
          <div className="str2">{STRINGS.str2}</div>
        </div>
        <div class='text'>
          <p>
            {STRINGS.str3} <a href='LINKS.email'>{STRINGS.str4}</a> {STRINGS.str5}
          </p>
        </div>
        <div className='icons'>
          <a href={LINKS.github} target='_blank'><div className='icon github'></div></a>
          <a href={LINKS.twitter} target='_blank'><div className='icon twitter'></div></a>
          <a href={LINKS.linkedin} target='_blank'><div className='icon linkedin'></div></a>
        </div>
      </div>
    );
  }
}


export default App;
