exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Sawarabi+Mincho&display=swap);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Josefin+Sans&display=swap);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);", ""]);
var getUrl = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL___0___ = getUrl(require("../images/github.svg"));
var ___CSS_LOADER_URL___1___ = getUrl(require("../images/twitter.svg"));
var ___CSS_LOADER_URL___2___ = getUrl(require("../images/linkedin.svg"));
// Module
exports.push([module.id, "\nbody {\n    padding: 0;\n    margin: 0;\n}\n\n.top {\n    background-color: #01579B;\n    color:white;\n    padding: 1em 0.4em 2em 0.4em;\n    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);\n}\n\n.text {\n    font-family: 'Roboto', sans-serif;\n    text-align: center;\n    padding: 5em 5em 0em 5em;\n    color: #263238;\n}\n\n.text a {\n    text-decoration: none;\n    color: #01579B;\n}\n\n.str1 {\n    padding: 2em 0;\n    text-align:center;\n    font-family: 'Sawarabi Mincho', sans-serif;\n    font-size: 3.8em;\n}\n.str2 {\n    font-size: 2.2em;\n    text-align:center;\n    font-family: 'Josefin Sans', sans-serif;\n}\n\n.icons {\n    text-align: center;\n    padding: 4em;\n}\n\n.icon {\n    border-radius: 50%;\n    display: inline-block;\n    width: 3.6em;\n    height: 3.6em;\n    margin: 0.6em;\n    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);\n    transition: all 0.3s cubic-bezier(.25,.8,.25,1);\n}\n\n.icon:hover {\n    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);\n}\n\n.github {\n    background: url(" + ___CSS_LOADER_URL___0___ + ");\n    background-size: contain;\n}\n\n.twitter {\n    background: url(" + ___CSS_LOADER_URL___1___ + ");\n    background-size: contain;\n}\n\n.linkedin {\n    background: url(" + ___CSS_LOADER_URL___2___ + ");\n    background-size: contain;\n}\n\n@media only screen and (min-width: 720px)  {\n    .top {\n        padding: 0em 2em 2em 2em;\n    }\n\n    .text {\n        padding: 5em 25em 0em 25em;\n    }\n    \n    .str1 {\n        padding: 0.6em 0;\n        font-size: 10em;\n    }\n    .str2 {\n        font-size: 4em;\n    }\n    \n    .icons {\n        padding: 4em;\n    }\n    \n    .icon {\n        width: 4em;\n        height: 4em;\n        margin: 1em;\n    }\n}", ""]);
